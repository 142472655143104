import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Pagina1 from './pagina1';
import Pagina2 from './pagina2';
import Pagina3 from './pagina3';
import Pagina4 from './pagina4';
import Pagina5 from './pagina5';
import './App.css';
import ResponsiveAppBar from './ResponsiveAppBar';

function App() {

  return (
    <Router>
      <ResponsiveAppBar />

      <div className="content">
        <Routes>
          <Route exact path='/pagina1' element={< Pagina1 />} />
          <Route exact path='/pagina2' element={< Pagina2 />} />
          <Route exact path='/pagina3' element={< Pagina3 />} />
          <Route exact path='/pagina4' element={< Pagina4 />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
