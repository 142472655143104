import logo from './logo.svg';
import './App.css';
import * as React from 'react';
import { sizing } from '@mui/system';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { palette } from '@mui/system';
import { Link } from 'react-router-dom'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const theme = {
  spacing: 8,
}


const sucursales = [
  {
    value: "",
    label: ""
  },
  {
    value: "Curicó",
    label: "Curicó"
  },
  {
    value: "Chillán",
    label: "Chillán"
  },
  {
    value: "Concepción",
    label: "Concepción"
  },
  {
    value: "Santiago",
    label: "Santiago"
  }
];

const vendedores = [
  {
    value: "",
    label: ""
  },
  {
    value: "juan",
    label: "juan"
  },
  {
    value: "pedro",
    label: "pedro"
  },
  {
    value: "pablo",
    label: "pablo"
  },
  {
    value: "Santiago",
    label: "Santiago"
  }
];

const tiposClientes = [
  {
    value: "",
    label: ""
  },
  {
    value: "persona natural",
    label: "persona natural"
  },
  {
    value: "persona juridica",
    label: "persona juridica"
  }
];


function Pagina3() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      width={'100%'}
      margin={0}
      justify="center"
      style={{ minHeight: '100vh' }}
      sx={{ alignContent: 'center', bgcolor: '#ADE9FF' }}

      noValidate
      autoComplete="off"
    >
      <div>
        <Card
          width={'100%'}
          sx={{
            p: 5, m: 5, borderRadius: 0,
            textAlign: 'center',
            boxShadow: 3
          }}>
          <TextField
            variant='standard'
            required
            id="txtCodigo"
            sx={{ width: '100%' }}
            label="Código promocional"
          >
          </TextField>
        </Card>
        <Card
          width={'100%'}
          sx={{
            p: 5, m: 5, borderRadius: 0,
            textAlign: 'center',
            boxShadow: 3
          }}>
          <TextField
            variant='standard'
            id="spinKit"
            sx={{ width: '100%' }}
            select
            label="Seleccione Kit *"
            SelectProps={{
              native: true,
            }}
          >
            {vendedores.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Card>
        <Card
          width={'100%'}
          sx={{
            p: 5, m: 5, borderRadius: 0,
            textAlign: 'center',
            boxShadow: 3
          }}>
          <TextField
            variant='standard'
            id="txtCantidad"
            sx={{ width: '100%' }}
            label="Cantidad *"
          >
          </TextField>
        </Card>
        <Card
          width={'100%'}
          sx={{
            p: 5, m: 5, borderRadius: 0,
            textAlign: 'center',
            boxShadow: 3
          }}>
          <TextField
            variant='standard'
            id="spinModelocasa"
            sx={{ width: '100%' }}
            select
            label="Seleccione Modelo de Casa *"
            SelectProps={{
              native: true,
            }}
          >
            {vendedores.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Card>

        <Card
          width={'100%'}
          sx={{
            p: 5, m: 5, borderRadius: 0,
            textAlign: 'center',
            boxShadow: 3
          }}>
          <TextField
            variant='standard'
            id="spinForradoexterior"
            sx={{ width: '100%' }}
            select
            label="Forrado exterior *"
            SelectProps={{
              native: true,
            }}
          >
            {vendedores.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Card>

        <Card
          width={'100%'}
          sx={{
            p: 5, m: 5, borderRadius: 0,
            textAlign: 'center',
            boxShadow: 3
          }}>
          <FormGroup>
            <FormControlLabel control={<Checkbox />} label="Fieltro techo" />
            <FormControlLabel control={<Checkbox />} label="Limahoyas" />
            <FormControlLabel control={<Checkbox />} label="Otro" />
          </FormGroup>
          <TextField
            disabled
            variant='standard'
            id="txtOtroregalo"
            sx={{ width: '100%' }}
            label="Otro"
          >
            {vendedores.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Card>

        <Card
          width={'100%'}
          sx={{
            p: 5, m: 5, borderRadius: 0,
            textAlign: 'center',
            boxShadow: 3
          }}>
          <TextField
            variant='standard'
            id="spinModificacionestructural"
            sx={{ width: '100%' }}
            select
            label="Modificacion estructural *"
            SelectProps={{
              native: true,
            }}
          >
            {vendedores.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Card>


        <div width={'100%'}>
          <Box
            sx={{
              display: 'table',
              alignContent: 'center',
              p: 2
            }}
          >
            <Link to="/pagina4">
              <Button id="btnSiguiente" variant="contained" sx={{ p: 2, m: 3, gridRow: '1', gridColumn: 'span 5', borderRadius: 0 }}>Siguiente</Button>
            </Link>
            <Button id="outlined-btnBorrar" variant="text" sx={{ p: 2, m: 3, gridRow: '1', gridColumn: 'span 50', borderRadius: 0 }}>Borrar formulario</Button>
          </Box>
        </div>

      </div>

    </Grid>
  );
}

export default Pagina3;
